<template>
		
	<v-dialog
		v-model="dialog"
		@click:outside="accept"
		class="pa-0 ma-0"
	>
		<v-card
			class="pa-0 ma-0"
		>
			<v-card-title 
				class="d-block"
				style="border-bottom: 1px solid #ddd;"
			>
				주소 찾기
				
				<v-btn
					class="float-right pa-0 ma-0"
					outlined
					small
					@click="accept"
					style="min-width: 0;"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			
			<v-card-text
				class="pa-0"
			>
				<VueDaumPostcode
					:q="q"
					@complete="daumComplate"
					class="pa-0"
				>
				</VueDaumPostcode>
			</v-card-text>
		</v-card>
	</v-dialog>
	
</template>

<script>

	import { VueDaumPostcode } from "vue-daum-postcode"
	
	export default{
		name: 'DaumPost'
		,props: ['modal']
		,components: {VueDaumPostcode}
		,data () {
			return {
				dialog: false
				,item: {
				}
				,q: ''
			}
		}
		,methods: {
			callModal: function(){
				console.log('callModal')
				this.q = ''
				this.dialog = true
			}
			,removeModal: function(){
				console.log('removeModal')
				this.dialog = false
			}
			,daumComplate: function(call){
				this.$emit('callBack', call)
				this.accept()
			}
			,accept: function(){
				this.$emit('removeModal')
			}
		}
		,watch: {
			modal: {
				deep: true
				,handler: function(call){
					console.log('watch modal')
					if(call){
						this.callModal()
					}else{
						this.removeModal()
					}
				}
			}
		}
	}
</script>