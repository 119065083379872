<template>
	<div
		class="SignAccount mt-10"
	>
		<div class="pa-3">
			<v-text-field
				v-model="item.email"
				label="이메일"
				placeholder="이메일을 입력하세요"
				type="email"
				color="success2"
			>
			</v-text-field>
			
			<v-text-field
				v-model="item.zipCode"
				label="우편번호"
				placeholder="우편번호를 입력하세요"
				
				hide-detail
				readonly
				color="success2"
				append-icon="search"
				@click:append="daumPost"
			></v-text-field>
			
			<v-text-field
				v-model="item.addressMain"
				label="주소"
				placeholder="주소를 입력하세요"
				
				hide-detail
				readonly
				color="success2"
				
			></v-text-field>
			
			<v-text-field
				v-model="item.addressDetail"
				label="상세주소"
				placeholder="상세주소를 입력하세요"
				
				hide-detail
				color="success2"
			></v-text-field>
		</div>
		
		<DaumPost
			:modal="daumPostUp"
			
			@removeModal="removeModal"
			@callBack="addPost"
		></DaumPost>
	</div>
</template>

<script>

	import DaumPost from '@/components/DaumPost'
	
	export default{
		name: 'UserSetting'
		,created: function(){
			this.$emit('setProgram', this.program)
		}
		,components: { DaumPost }
		,props: ['user', 'callBack']
		,data: function(){
			return {
				program: {
					title: '판매자 정보'
					,next: {
						title: '정보수정'
						,do: true
						,callBack: 'postUserMe'
					}
					,not_navigation: true
				}
				,item: {
					addressDetail: ''
					,addressMain: ''
					,email: ''
					,zipCode: ''
				}
				,daumPostUp: false
			}
		}
		,methods: {
			postUserMe: function(){
				this.$emit('axios', {
					request_type: 'put'
					,request_url: '/users/me'
					,request_params: this.item
					,authorize: true
					,callBack: 'postUserMeResult'
				})
			}
			,postUserMeResult: function(call){
				if(call.result){
					this.$emit('alertMsg', 'success', '정상적으로 처뢰되었습니다')
				}else{
					this.$emit('alertMsg', 'error', call.message)
				}
			}
			,daumPost: function(){
				this.daumPostUp = true
			}
			,addPost: function(call){
				this.$set(this.item, 'zipCode', call.zonecode)
				this.$set(this.item, 'addressMain', call.address)
			}
			,removeModal: function(){
				console.log('removeModal')
				this.daumPostUp = false
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'postUserMe'){
						this.postUserMe()
					}else if(call.name == 'postUserMeResult'){
						this.postUserMeResult(call)
					}
				}
			}
		}
	}
</script>

<style>
	.SignAccount .v-label {font-size: 1.2em !important; font-weight: bold; color: black !important; top: -5px !important;}
</style>